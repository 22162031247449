/* Footer */

#brown-footer {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  color: $light;
  background-color: $brown;
  padding: 0;
}
#brown-footer .marginator {
  width: 100%;
}
#brown-footer section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#brown-footer section h1 {
  text-indent: -999em;
  height: 30px;
  width: 125px;

  background-size: contain;
  margin: 2rem auto 1em auto;
  order: 1;
}
#brown-footer section #give-to-brown {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  order: 2;
}
#brown-footer section #give-to-brown .giving-footer {
  display: inline-block;
  width: auto;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  text-decoration: none;
  background-color: #ed1c24;
  padding: 20px 15px;
}
#brown-footer section #give-to-brown .giving-footer:hover {
  text-decoration: none;
  background-color: #c00404;
}
#brown-footer #brown-contact {
  width: 100%;
}
#brown-footer #brown-contact .contact-location {
  width: 100%;
  display: inline-block;
  text-align: center;
  list-style-type: none;
  padding: 2rem;
  margin: 1rem auto;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
#brown-footer #brown-contact .contact-location li {
  display: inline-block;
  font-family: Circular-Book, Arial, Helvetica, sans-serif;
  line-height: 2.75;
  margin-right: 1.75rem;
}
#brown-footer #brown-contact .contact-location li:last-of-type {
  margin-right: 0;
}
#brown-footer #brown-contact .contact-location li a {
  color: $light;
  text-decoration: none;
}
#brown-footer #brown-contact .contact-location li a:hover {
  color: #ffc72c;
  text-decoration: underline;
}
#brown-footer .i-destination::before,
#brown-footer .i-phone::before,
#brown-footer .i-devices::before,
#brown-footer .i-map::before,
#brown-footer .i-split::before {
  font-size: 1.2em;
  padding-right: 0.9rem;
  opacity: 0.4;
}
#brown-footer #social-media {
  display: block;
  padding: 0 1rem;
}
#brown-footer #social-media ul {
  display: inline-block;
  float: left;
  text-align: center;
  list-style-type: none;
  margin: 20px auto;
}
#brown-footer #social-media ul li {
  display: inline-block;
  text-align: center;
}
#brown-footer #social-media ul li a {
  font-size: 1.5em;
  text-decoration: none;
  color: $yellow;
  background-color: transparent;
  margin-right: 15px;
  cursor: pointer;
}
#brown-footer #social-media ul li a:hover {
  color: $light;
}
#brown-footer #social-media .social-link {
  display: inline-block;
  float: right;
  width: auto;
  text-align: center;
}
#brown-footer #social-media .social-link a {
  display: block;
  width: 100%;
  color: $light;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  border: 1px solid $yellow;
  padding: 15px 20px;
  margin: 20px auto 40px auto;
}
#brown-footer #social-media .social-link a::after {
  display: inline-block;
  position: relative;
  top: 0;
  width: 0;
  height: 0;
  margin-left: 8px;
  -webkit-transition: 0.25s border;
  transition: 0.25s border;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent $yellow;
  content: '';
}
#brown-footer #social-media .social-link a:hover {
  background-color: $brown-200;
  border-color: $brown-200;
  color: $yellow;
}
#brown-footer #social-media .social-link a:hover::after {
  border-color: transparent transparent transparent $yellow;
}
#brown-footer #footer-copyright {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
}
#brown-footer #footer-copyright p {
  font-size: 0.9em;
  text-align: center;
  color: $light;
}
#brown-footer #footer-copyright p .copyright {
  font-family: Circular-Book, Arial, Helvetica, sans-serif;
  padding-right: 15px;
}
#brown-footer #footer-copyright p .link-top {
  display: inline;
  font-family: Circular-Book, Circular-Book, Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: $light;
  padding-left: 15px;
}
#brown-footer #footer-copyright p .link-top:hover {
  color: #ffc72c;
}
#brown-footer #footer-copyright p .link-top .i-arrow-up-line {
  color: #ffc72c;
}
f .university-nav-red #brown-footer {
  background-color: #c00404;
}
.university-nav-red #brown-footer section #give-to-brown .giving-footer {
  background-color: rgba(255, 255, 255, 0.25);
}
.university-nav-red #brown-footer section #give-to-brown .giving-footer:hover {
  color: #c00404;
  background-color: #ffffff;
}
.university-nav-red #brown-footer #footer-copyright .topjump .link-top {
  background-color: #e5e5e5;
}

.university-nav-white #brown-footer {
  background-color: #3c3c3c;
}
.university-nav-white #brown-footer #footer-copyright .topjump .link-top {
  background-color: #e5e5e5;
}

@media only screen and (min-width: 480px) and (max-width: 699px) {
  #brown-footer #social-media ul li a {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 699px) {
  #brown-footer #social-media ul,
  #brown-footer #social-media .social-link {
    display: block;
    text-align: center;
    float: none;
  }
  #brown-footer #social-media .social-link {
    width: 300px;
    margin: 0 auto;
  }
  #brown-footer #brown-contact {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #brown-footer #footer-copyright {
    padding: 0;
  }
  #brown-footer #footer-copyright p {
    display: block;
    margin: 0 auto;
    padding: 20px 0;
  }
}
@media only screen and (min-width: 700px) {
  #brown-footer {
    width: 100%;
  }
  #brown-footer section {
    flex-direction: row;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #brown-footer section h1 {
    margin-left: 0;
  }
  #brown-footer section #give-to-brown {
    float: right;
    margin: 0;
  }
  #brown-footer section #give-to-brown .giving-footer {
    margin: 30px 0;
  }
  #brown-footer #brown-contact .contact-location {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  #brown-footer #social-media ul li a {
    margin-right: 30px;
  }
  #brown-footer #footer-copyright {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 900px) {
  #brown-footer section {
    align-items: center;
  }
  #brown-footer section h1 {
    height: 30px;
    width: 270px;
    margin: 30px 0;
  }
}
@media only screen and (min-width: 0) and (max-width: 1299px) {
  #brown-footer #brown-contact {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 1300px) {
  #brown-footer #brown-contact,
  #brown-footer section {
    padding-left: 0;
    padding-right: 0;
  }
  #brown-footer #social-media {
    padding: 0;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 83.125em) {
  #brown-footer .marginator {
    width: 98%;
  }
}

@include media-breakpoint-down(sm) {
  #brown-footer {
    display: none;
  }
}
