// Overrides Bootstrap Colors
$blue: #003c71;
$red: #c00404;
$yellow: #ffc72c;
$green: #00b398;
$cyan: #59cbe8;
$gray-600: #98a4ae;

// Additional Brown University Theme Colors
$brown: #4e3629;
$beige: #efece5;
$sand: #ccc6b8;
$light-brown: #a79a94;
$medium-brown: #745d54;
$dark-brown: #4e3629;
$brown-100: #a79a94;
$brown-200: #745d54;

$custom-colors: (
  'brown': $brown,
  'beige': $beige,
  'sand': $sand,
  'light-brown': $light-brown,
  'medium-brown': $medium-brown,
  'dark-brown': $dark-brown,
);

$primary: $blue;
$secondary: $yellow;
