/* start Circular fonts */

@font-face {
  font-family: Circular-Bold;
  src: url('../fonts/circularBold/lineto-circular-bold.eot');
  src:
    url('../fonts/circularBold/lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/circularBold/lineto-circular-bold.woff') format('woff'),
    url('../fonts/circularBold/lineto-circular-bold.ttf') format('truetype'),
    url('../fonts/circularBold/lineto-circular-bold.svg#lineto-circular-bold') format('svg');
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: Circular-Book;
  src: url('../fonts/circularBook/lineto-circular-book.eot');
  src:
    url('../fonts/circularBook/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/circularBook/lineto-circular-book.woff') format('woff'),
    url('../fonts/circularBook/lineto-circular-book.ttf') format('truetype'),
    url('../fonts/circularBook/lineto-circular-book.svg#lineto-circular-book') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
